import type { AppProps } from 'next/app'
import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePostHog } from 'next-use-posthog';
import '../styles/main.scss'

const setSmoothScroll = (isSmooth: boolean) => {
  document.documentElement.style.scrollBehavior = isSmooth ? 'smooth' : 'auto';
};

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || (process.env.NEXT_PUBLIC_VERCEL_URL ? ('https://' + process.env.NEXT_PUBLIC_VERCEL_URL) : 'https://www.joshheng.co.uk');

function MyApp({ Component, pageProps }: AppProps) {
  usePostHog(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    loaded: posthog => {
      if (localStorage.getItem('OPT_OUT') === '1') posthog.opt_out_capturing();
    }
  });

  const router = useRouter();

  useEffect(() => {
    setSmoothScroll(true);

    const handleStart = () => setSmoothScroll(false);
    const handleStop = () => setSmoothScroll(true);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    }
  }, [router]);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1' />
        <title>Josh Heng</title>
        <meta name='theme-color' content='#353C6F' />
        <link rel='shortcut icon' type='image/jpg' href={`${baseUrl}/profile-picture.jpg`} />
        <link rel='apple-touch-icon' href={`${baseUrl}/profile-picture.jpg`} />
        <link rel='apple-touch-icon-precomposed' href={`${baseUrl}/profile-picture.jpg`} />
        <meta property='og:site_name' content='Josh Heng' />
        <meta property='og:locale' content='en_GB' />
        <meta property='og:url' content={baseUrl} />
      </Head>

      <Component {...pageProps} />
    </>
  );
}

export default MyApp
